<template>
  <div>
    <v-alert
      v-if="error"
      outlined
      type="error"
      transition="scale-transition"
    >
      Error en el servicio {{ errMessage }}
    </v-alert>
    <v-row v-if="!error">
      <v-col
        cols="12"
        md="12"
      >
        <stadistics ref="stadistics"></stadistics>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <sentimiento ref="sentimiento"></sentimiento>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <autores ref="autores"></autores>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import Autores from './Autores'
import Sentimiento from './Sentimiento'
import Stadistics from './Stadistics'
import service from '@/services/redesSociales'
import auth from '@/services/auth'

export default {
  components: {
    Stadistics,
    Sentimiento,
    Autores,
  },
  data() {
    return {
      error: false,
      errMessage: '',
      params: {
        queryId: '82909',
      },
    }
  },
  computed: {
    isLoggedIn() {
      return auth.isLoggin()
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push(`/pages/login?return=${this.$route.fullPath}`)
    }
    this.getData()
  },
  methods: {
    getData() {
      service.getRedes(this.params).then(resp => {
        resp.data.sort((a, b) => {
          const fechaA = moment(a.fecha_creacion)
          const fechaB = moment(b.fecha_creacion)
          if (fechaA > fechaB) {
            return -1
          }
          if (fechaA < fechaB) {
            return 1
          }

          return 0
        })
        this.datos = resp.data
        this.$refs.sentimiento.setData(resp.data)
        this.formatData()
      }).catch(e => {
        this.error = true
        this.errMessage = e.response.data
      })
    },
    formatData() {
      const redTracker = {
        facebook: 0,
        twitter: 0,
        instagram: 0,
        youtube: 0,
        blog: 0,
        news: 0,
        reddit: 0,
        board: 0,
      }
      const topAutores = {

      }

      this.datos.forEach(dato => {
        if (dato.red in redTracker) {
          redTracker[dato.red] += 1
        }
        if (!(dato.autor.username in topAutores)) {
          topAutores[dato.autor.username] = {
            total: 0,
            username: dato.autor.username,
            avatar: dato.autor.avatar,
            link: dato.autor.link,
            red: dato.red,
          }
        }
        topAutores[dato.autor.username].total += 1
      })
      const arrAutores = []
      Object.keys(topAutores).forEach(k => {
        if (topAutores[k].total > 1) {
          arrAutores.push(topAutores[k])
        }
      })
      arrAutores.sort((a, b) => {
        if (a.total > b.total) {
          return -1
        }
        if (a.total < b.total) {
          return 1
        }

        return 0
      })
      this.$refs.autores.setData(arrAutores)
      this.$refs.stadistics.setData(redTracker)
    },
  },

}
</script>
